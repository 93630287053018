import React from 'react';
import Layout from '../../layout/pl';
import TeamPerson from '../../components/TeamPerson';

import Monika from '../../images/team/monika-small.png';

import cert1 from '../../images/certyfikaty_monika/Scan.jpg';
import cert2 from '../../images/certyfikaty_monika/Scan2.jpg';
import cert3 from '../../images/certyfikaty_monika/Scan3.jpg';
import cert4 from '../../images/certyfikaty_monika/Scan4.jpg';
import cert5 from '../../images/certyfikaty_monika/Scan5.jpg';
import cert6 from '../../images/certyfikaty_monika/Scan6.jpg';
import cert7 from '../../images/certyfikaty_monika/Scan7.jpg';
import cert8 from '../../images/certyfikaty_monika/Scan8.jpg';
import cert9 from '../../images/certyfikaty_monika/Scan9.jpg';
import cert10 from '../../images/certyfikaty_monika/Scan10.jpg';

const certification = [ cert1, cert2, cert3, cert4, cert5, cert6, cert7, cert8, cert9, cert10 ];

const MonikaBochenek = (props) => {
	const handleClick = () => {
		window.medicall_modal('4d2681f0fb3e6bef7d87');
		return false;
	};

	return (
		<Layout {...props}>
			<TeamPerson img={Monika} handleClick={handleClick} certification={certification} person="monika" />
		</Layout>
	);
};
// }
export default MonikaBochenek;
